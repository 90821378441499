import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MandalaInput from "./components/mandalaInput";
import MandalaOutput from "./components/mandalaOutput";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © Mandala Generator "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 2, mb: 2 }} maxWidth="lg">
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ mt: 4, mb: 4 }}
          >
            Параметры мандалы
          </Typography>
          <MandalaInput />
          <MandalaOutput />
        </Container>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
