import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOutputType } from "../../store/rootSlice";

import { TextField, MenuItem } from "@mui/material";
import { OUTPUT_TYPES } from "../../utils";

function InputSelect() {
  const outputType = useSelector((state) => state.root.outputType);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setOutputType(event.target.value));
  };

  return (
    <TextField
      sx={{ width: 1 }}
      id="outlined-select-output-type"
      select
      label="Какие мандалы генерировать"
      variant="outlined"
      value={outputType}
      onChange={handleChange}
    >
      {OUTPUT_TYPES.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default InputSelect;
