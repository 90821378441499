import * as React from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";

function MandalaOutput() {
  let output = useSelector((state) => state.root.output);

  return (
    <Box>
      <Typography variant="h5" component="div" gutterBottom sx={{ mt: 4 }}>
        Результаты
      </Typography>

      {output &&
        output.map((svg, i) => (
          <Paper
            elevation={3}
            square
            key={`mandala_${i}`}
            sx={{
              mt: 4,
              mb: 4,
            }}
          >
            <img src={`data:image/svg+xml;utf8,${svg}`} alt="" width="100%" />
          </Paper>
        ))}
    </Box>
  );
}

export default MandalaOutput;
