import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInput } from "../../store/rootSlice";

import TextField from "@mui/material/TextField";

function InputText() {
  const input = useSelector((state) => state.root.inputString);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setInput(event.target.value));
  };

  return (
    <TextField
      sx={{ width: 1 }}
      id="outlined-basic"
      label="Ваша фраза"
      variant="outlined"
      value={input}
      onChange={handleChange}
    />
  );
}

export default InputText;
