export const OUTPUT_TYPES = [
  {
    value: "set",
    label: "Пустая + цифровой шаблон + цветная",
  },
  {
    value: "skel",
    label: "Пустая",
  },
  {
    value: "temp",
    label: "Цифровой шаблон",
  },
  {
    value: "fill",
    label: "Цветная",
  },
];
