import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCount } from "../../store/rootSlice";

import { TextField } from "@mui/material";

function InputNumber() {
  const count = useSelector((state) => state.root.countParam);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setCount(event.target.value));
  };

  return (
    <TextField
      sx={{ width: 1 }}
      id="outlined-basic"
      label="Верхняя часть"
      variant="outlined"
      value={count}
      onChange={handleChange}
    />
  );
}

export default InputNumber;
