/**
 * @fileoverview gRPC-Web generated client stub for mandala
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.mandala = require('./mandala_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mandala.MandalaClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mandala.MandalaPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mandala.MandalaRequest,
 *   !proto.mandala.MandalaListResponse>}
 */
const methodDescriptor_Mandala_GetMandalaList = new grpc.web.MethodDescriptor(
  '/mandala.Mandala/GetMandalaList',
  grpc.web.MethodType.UNARY,
  proto.mandala.MandalaRequest,
  proto.mandala.MandalaListResponse,
  /**
   * @param {!proto.mandala.MandalaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mandala.MandalaListResponse.deserializeBinary
);


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mandala.MandalaListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mandala.MandalaListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mandala.MandalaClient.prototype.getMandalaList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mandala.Mandala/GetMandalaList',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetMandalaList,
      callback);
};


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mandala.MandalaListResponse>}
 *     Promise that resolves to the response
 */
proto.mandala.MandalaPromiseClient.prototype.getMandalaList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mandala.Mandala/GetMandalaList',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetMandalaList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mandala.MandalaRequest,
 *   !proto.mandala.MandalaObject>}
 */
const methodDescriptor_Mandala_GetSkeleton = new grpc.web.MethodDescriptor(
  '/mandala.Mandala/GetSkeleton',
  grpc.web.MethodType.UNARY,
  proto.mandala.MandalaRequest,
  proto.mandala.MandalaObject,
  /**
   * @param {!proto.mandala.MandalaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mandala.MandalaObject.deserializeBinary
);


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mandala.MandalaObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mandala.MandalaObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mandala.MandalaClient.prototype.getSkeleton =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mandala.Mandala/GetSkeleton',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetSkeleton,
      callback);
};


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mandala.MandalaObject>}
 *     Promise that resolves to the response
 */
proto.mandala.MandalaPromiseClient.prototype.getSkeleton =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mandala.Mandala/GetSkeleton',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetSkeleton);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mandala.MandalaRequest,
 *   !proto.mandala.MandalaObject>}
 */
const methodDescriptor_Mandala_GetTemplate = new grpc.web.MethodDescriptor(
  '/mandala.Mandala/GetTemplate',
  grpc.web.MethodType.UNARY,
  proto.mandala.MandalaRequest,
  proto.mandala.MandalaObject,
  /**
   * @param {!proto.mandala.MandalaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mandala.MandalaObject.deserializeBinary
);


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mandala.MandalaObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mandala.MandalaObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mandala.MandalaClient.prototype.getTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mandala.Mandala/GetTemplate',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetTemplate,
      callback);
};


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mandala.MandalaObject>}
 *     Promise that resolves to the response
 */
proto.mandala.MandalaPromiseClient.prototype.getTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mandala.Mandala/GetTemplate',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mandala.MandalaRequest,
 *   !proto.mandala.MandalaObject>}
 */
const methodDescriptor_Mandala_GetFilled = new grpc.web.MethodDescriptor(
  '/mandala.Mandala/GetFilled',
  grpc.web.MethodType.UNARY,
  proto.mandala.MandalaRequest,
  proto.mandala.MandalaObject,
  /**
   * @param {!proto.mandala.MandalaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mandala.MandalaObject.deserializeBinary
);


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mandala.MandalaObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mandala.MandalaObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mandala.MandalaClient.prototype.getFilled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mandala.Mandala/GetFilled',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetFilled,
      callback);
};


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mandala.MandalaObject>}
 *     Promise that resolves to the response
 */
proto.mandala.MandalaPromiseClient.prototype.getFilled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mandala.Mandala/GetFilled',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetFilled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mandala.MandalaRequest,
 *   !proto.mandala.MandalaObject>}
 */
const methodDescriptor_Mandala_GetFilledTemplate = new grpc.web.MethodDescriptor(
  '/mandala.Mandala/GetFilledTemplate',
  grpc.web.MethodType.UNARY,
  proto.mandala.MandalaRequest,
  proto.mandala.MandalaObject,
  /**
   * @param {!proto.mandala.MandalaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mandala.MandalaObject.deserializeBinary
);


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mandala.MandalaObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mandala.MandalaObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mandala.MandalaClient.prototype.getFilledTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mandala.Mandala/GetFilledTemplate',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetFilledTemplate,
      callback);
};


/**
 * @param {!proto.mandala.MandalaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mandala.MandalaObject>}
 *     Promise that resolves to the response
 */
proto.mandala.MandalaPromiseClient.prototype.getFilledTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mandala.Mandala/GetFilledTemplate',
      request,
      metadata || {},
      methodDescriptor_Mandala_GetFilledTemplate);
};


module.exports = proto.mandala;

