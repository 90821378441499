import { createSlice } from "@reduxjs/toolkit";
import { OUTPUT_TYPES } from "../utils";

const initialState = {
  inputString: "я абсолютно здорова",
  countParam: "8",
  outputType: OUTPUT_TYPES[0].value,
  output: [],
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setInput: (state, action) => {
      state.inputString = action.payload;
    },
    setCount: (state, action) => {
      state.countParam = action.payload;
    },
    setOutput: (state, action) => {
      state.output = action.payload;
    },
    setOutputType: (state, action) => {
      state.outputType = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInput, setCount, setOutput, setOutputType, reset } =
  rootSlice.actions;

export default rootSlice.reducer;
