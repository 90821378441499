import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import { Button } from "@mui/material";

import InputText from "../inputText";
import InputNumber from "../inputNumber";
import InputSelect from "../inputSelect";

import { setOutput } from "../../store/rootSlice";
import { OUTPUT_TYPES } from "../../utils";
import { ClientContext } from "../../index";
import { MandalaRequest } from "../../stubs/mandala_rpc/proto/mandala_pb";

function MandalaInput() {
  const { inputString, countParam, outputType } = useSelector(
    (state) => state.root
  );
  const mandalaClient = useContext(ClientContext);

  const dispatch = useDispatch();

  const handleClick = () => {
    const request = new MandalaRequest();
    request.setInput(inputString);
    request.setPrecision(countParam);

    try {
      if (outputType === OUTPUT_TYPES[1].value) {
        // Skeleton
        mandalaClient.getSkeleton(request, {}, (err, response) => {
          // console.log("Input", response.getInput());
          // console.log("Precision", response.getPrecision());
          // console.log("Svg", response.getSvg());
          dispatch(setOutput([encodeURIComponent(response.getSvg())]));
        });
      } else if (outputType === OUTPUT_TYPES[2].value) {
        // Template
        mandalaClient.getTemplate(request, {}, (err, response) => {
          // console.log("Input", response.getInput());
          // console.log("Precision", response.getPrecision());
          // console.log("Svg", response.getSvg());
          dispatch(setOutput([encodeURIComponent(response.getSvg())]));
        });
      } else if (outputType === OUTPUT_TYPES[3].value) {
        // Filled
        mandalaClient.getFilled(request, {}, (err, response) => {
          // console.log("Input", response.getInput());
          // console.log("Precision", response.getPrecision());
          // console.log("Svg", response.getSvg());
          dispatch(setOutput([encodeURIComponent(response.getSvg())]));
        });
      } else {
        // All
        mandalaClient.getMandalaList(request, {}, (err, response) => {
          dispatch(
            setOutput([
              ...response
                .getListList()
                .map((item) => encodeURIComponent(item.getSvg())),
            ])
          );
        });
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <InputText />
      </Grid>
      <Grid item md={2} xs={12}>
        <InputNumber />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputSelect />
      </Grid>
      <Grid item md={4}>
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          size="large"
          onClick={handleClick}
        >
          Посчитать
        </Button>
      </Grid>
    </Grid>
  );
}

export default MandalaInput;
